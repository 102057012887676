import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core'; 
import { CommonModule } from '@angular/common';   
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';  
import { Tipologica } from '../../interfaces/tipologica';
import { LanguagesService } from './../../services/languages/languages.service';

@Component({
  selector: 'app-combo-custom',  
  standalone: true,
  imports: [CommonModule ],
  template: `   
        <div class="mb-0"> 
            <label *ngIf="label !== ''" for="type" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white first-letter:capitalize">{{label}}</label> 
            <div class="relative ">
                <div>
                  <button 
                    (click)="optionDrd.hidden = !optionDrd.hidden" type="button" 
                    class="customFld " 
                    aria-haspopup="true" aria-expanded="true">
                    <span #typeLbl class="w-full flex items-center justify-start capitalize">{{placeholder}} ...</span>                                
                    <img src="../../../assets/img/download.svg" class="w-6" alt="drop down arrow">
                  </button>
                </div>                            
                <div #optionDrd hidden="true" class="customDropDown ">
                  <div class="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu"> 
                    <a *ngFor="let item of data; let i = index"
                      (click)="chooseValue(item ); optionDrd.hidden = !optionDrd.hidden"
                      class="customOption " 
                      role="menuitem">
                      <img *ngIf="item?.ico" [src]="item?.ico" class="h-4 mr-2 bg-trasparent" /> 
                      <span class="capitalize">{{ secureTranslation(item.label) }} </span>
                    </a>                  
                  </div>
                </div>
            </div>                          
        </div> 
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboCustomComponent),
      multi: true
    }
  ],
  styles: [` 
    .customFld {
      @apply  flex items-center justify-between  leading-[1rem]  py-2 pl-3  w-full  
        shadow-sm  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
        focus:ring-gray-500 focus:border-gray-500   dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:text-white ;
    }
    .customFld > span{ 
      padding:  0;
    } 
    .customDropDown {
      z-index: 99999999;
      @apply  max-h-60 w-auto px-2 overflow-y-auto rounded-bl-md  origin-top-right absolute z-max right-0 mt-0  shadow-lg bg-gray-300 ring-1 ring-black ring-opacity-5;
    }
    .customOption {
      @apply cursor-pointer flex items-center block px-[0.3rem] py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-700 ;
    }
  `]
})
export class ComboCustomComponent implements ControlValueAccessor, OnChanges {  
   
    @Output() setFieldValue = new EventEmitter<string>(); 
    @Input() value = '' ;  
    @Input() label = '' ;   
    @Input() placeholder?: string =  this.langService.translations$.value['placeholder-select'] ?? 'placeholder-select';  
    @Input() data: Tipologica[] =  [];  
      
    @ViewChild('typeLbl', {static: true}) typeLbl!: ElementRef<HTMLElement> ; 

    constructor( private langService: LanguagesService ) {}


    ngOnChanges(changes: SimpleChanges) { 
      if (changes['value'] && !changes['value'].firstChange ) {
        this.typeLbl.nativeElement.innerHTML = (changes['value'].currentValue === '') ? this.placeholder : changes['value'].currentValue ;
      }  

      if (changes['value'] && changes['value'].firstChange ) { 
        this.setValue(this.data,  changes['value'].currentValue  );
      }  

      if (changes['data'] && !changes['data'].firstChange) {
        const data = changes['data'].currentValue;
        const val = this.value;   
        if(val) {
          this.setValue(data, val );
        }  
      }
    } 

    setValue(data : any[], value: string  ) {    

      if(data.length === 0) return;

      if(!value) return;
      
      const ico = this.getIcon(data, value);   
      if(ico === '') {
        this.typeLbl.nativeElement.innerHTML = '<span class="capitalize">' + this.getLabel( data, value) + '</span>' ; 
      } else {
        this.typeLbl.nativeElement.innerHTML = '<img src="' + ico +'" class=" h-4 mr-2 bg-trasparent" /> <span class=" capitalize">' + this.getLabel( data, value) + '</span>' ; 
      }  
    }

    chooseValue(item : any ) {    
      if(!item.ico || item.ico === '') {
        this.typeLbl.nativeElement.innerHTML = '<span class="capitalize">' + this.secureTranslation(item.label) + '</span>' ; 
      } else {
        this.typeLbl.nativeElement.innerHTML = '<img src="' + item.ico +'" class="h-4 mr-2  bg-trasparent" /> <span class=" capitalize">' + item.label  + '</span>' ; 
      } 
      this.setFieldValue.emit(item.value);
    }

    getLabel( data : any[], value: string): string{  
      if(data.length === 0) return value;
      return data.find( i => i.value === value )?.label ?? value;  
    }

    secureTranslation(label: string): string { 
       let itemLabel = label.replace( /_/g, '-' ).toLowerCase();
       itemLabel = this.langService.translations$.value[itemLabel] ?? itemLabel ;  
      //  label = itemLabel.toUpperCase(); //this.capitalizePipe.transform( itemLabel) ; 
       return itemLabel;
    }
 

    getIcon( data : any[], value: string): string {  
      if(data.length === 0)  return '';   
      const preloadValue = data.find( i => i.value === value ) || {}; 
      return ( 'ico' in preloadValue) ? preloadValue.ico : '';   
    }

    writeValue(obj: any): void {
      //! don't remove also if unused 
    }  
    
    registerOnChange(fn: any): void { 
      //! don't remove also if unused
    }  
    
    registerOnTouched(fn: any): void { 
      //! don't remove also if unused
    }  
}

 
