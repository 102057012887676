import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AppState, AuthService } from '@auth0/auth0-angular'; 
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, map, of, takeUntil  } from 'rxjs';  
import { Router, NavigationEnd, RouterModule } from '@angular/router';    
import { AuthenticationService } from '../../../services/authentication.service';
import { UtilsService } from '../../../services/utils.service';
import { getAppState } from '../../../store/app.selectors';
import { LanguagesService } from '../../../services/languages/languages.service'; 
import { Modal } from 'flowbite'; 
import { updatePlatform } from '../../../store/app.actions'; 
import { SpinnerComponent } from './../spinner.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../../shared/shared.module';
import { ComboCustomComponent } from './../combo.component';
import { FormsModule } from '@angular/forms';
import { ErrorsService } from '../../vehicles/views/vehicles/details/errors/services/errors.service'; 
import { mapMenuitems } from './map-menu-items';  

import { environment as env } from './../../../../environments/environment';  
import { DocsService } from 'src/app/services/docs.service';
import { AppVersionComponent } from '../app-version/app-version.component';
 
 

type OpenSectionsType = {
  vehicles: boolean;  
  users: boolean;
  controlpanel: boolean;
  kitdoc: boolean;
  statistics: boolean;
  [key: string]: boolean;
};

@Component({
  selector: 'app-side-bar-menu', 
  standalone: true,
  imports: [AppVersionComponent, CommonModule, SharedModule, RouterModule, FormsModule, ComboCustomComponent, SpinnerComponent],
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss']
})
export class SideBarMenuComponent implements OnInit{  

  ENV: string = env.environment ;  

  domain = '';

  cookieLang = this.langService.checkMyFavouriteLangauge();   

  private unsubscribe$: Subject<void> = new Subject<void>(); 

  themeIcon = '';
  theme = ''; 
  
  appState$: Observable<any> = this.store.select(getAppState);
  pic$: Observable<any> = this.store.select(getAppState).pipe( map( (data: any) => data['loggedUser']?.['picture'] ?? '' ) ); 
   
  openSection = '';

  openSections: OpenSectionsType = { 
    vehicles: false,   
    users: false, 
    controlpanel: false,
    kitdoc: false,
    statistics: false
  };  
 
  menuSections = mapMenuitems; // esprime la struttura e l'alberazione del menu (ruolo per ruolo)
 
  mod: Modal | null = null;  
  loader = false;
  showError  = false;
  formValues: any[] = []; 
  response : any = null;
  error: any = null;
  
  modalUserSettingsData$: Observable<any[]> = this.store.select(getAppState).pipe( map( (data: any) => data['loggedUser']?.['metadata']?.['settings'] ?? [] ) ); 
  isModalOpen  = false;


  @ViewChild('sidebarMenu') sidebarMenu: ElementRef | undefined; 


  // kit documentale 
  isLoadingDocumentsKitList$: Observable<boolean> = of(false) ;     
  docsKitList: any[] = [];
  doctype = ''; 
 
  constructor(
    private store: Store<AppState>,
    public auth: AuthenticationService,
    public langService: LanguagesService, 
    public oAuth: AuthService,  
    private router: Router, 
    private errorService: ErrorsService,
    private utilService: UtilsService,
    private docsService: DocsService) {    
  }

  ngOnInit() {   
        // console.log('>>> TIMEZONE', Intl.DateTimeFormat().resolvedOptions().timeZone);

        this.checkAuthentications();

        this.domain = window.location.hostname; 

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.unsubscribe$)
        ).subscribe({
          next: (res: any) =>{ 
            const url = this.router.url; 
            const segments = url.split('/');  

            // SECTION
            this.openSection = segments[1];     
            this.closeOpensections(); 

            // console.log('>>> se sono in una sottosezione epando il suo menu');
            if (this.openSection) {
              this.expandMenuSection( this.openSection);
            }
          },  
          error: (err) => { console.error('>>>> ERROR IN ROUTER EVENT', err) } 
        }); 

        this.setInitialTheme();  

        this.getContactLinks(); // LOGIN NOT REQUIRED

        // takeUntil(this.unsubscribe$)  //? TOKNOW:  va usato sempre alla fine della operators chain
        //takeUntilDestroyed()   //? COMING SOON non richiede  this.unsubscribe$ e non richiede ngOnDestroy() { ... }
        //? questi sono gli unici operatori che vanno messi eccezionalmente dopo takeUntil o takeUntilDestroyed
        // lstat()
        // takeLast(n)
        // toArray()
        // reduce(fn)
        // count()
        // max(fn)
        // min(fn)
        //...

        //? SIGNAL era is coming ( I wil introdice in Angular 17 porting )
        // private myService = inject(MyService);
        // dataSignal = toSignal( this.store.select(getAppState).pipe(
        //   ... some operators
        //   switchMap( data => this.myService.doSomething(data))
        // ));
        // {{ dataSignal() | json }}   al posto di  "dat$ | async"  in html
        //? with SIGNAL no unsubsciption is required !!!  


        this.cookieLang = this.langService.checkMyFavouriteLangauge(); // get previous selected language from cookie
        this.langService.getFirebaselangs(); // for combo
        this.langService.getFirebaseTerms(this.cookieLang);  // to preload translation for menu

        this.fillSettingsFormData(); 
    
  }

  checkAuthentications() {  
      this.oAuth.isAuthenticated$.subscribe((isAuthenticated: boolean) => { 
        if (isAuthenticated) { 
          this.auth.getUserSettings(); // ora posso chiamarlo subito perché è svincolato dallo userId
          // this.store.dispatch(loadLoggedUser()); // per salvare i dati dell'utente loggato in store applicativo
          // this.getCoordinatesUser();
  
          // SET USER TIMEZONE ( for header parameter )
          // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; 
          // sessionStorage.setItem('country', browserTimezone); 
        }
      });  
  }


  // used ONLY in case of classic href navigation ( using angular "navigation" this method is unuseful )
  isLinkActive(path:string) {
    return this.router.url.includes(path);
  }

  // if needed this method hide the sidebar menu 
  // BUT DEBUG why using this method sidebar drawer never close again :) 
  closeSidebar() { 
     
      const element: HTMLElement = this.sidebarMenu?.nativeElement;

      // Aggiungi aria-hidden
      element.setAttribute('aria-hidden', 'true');
  
      // Rimuovi aria-modal e role
      element.removeAttribute('aria-modal');
      element.removeAttribute('role');
  
      // Aggiorna le classi
      element.classList.remove('transform-none');
      element.classList.add('-translate-x-full');

      const drawer : HTMLElement  = document.querySelector('[drawer-backdrop]') as HTMLElement;

      if(drawer){ 
        // document.querySelector('[drawer-backdrop]')!.remove(); 
        drawer.style.visibility = 'hidden';
      }
  }


 
 
  //? SET THE SELECTED LANGUAGE AND SAVE IT IN A COOKIE  
  setSelectedLanguage(lng: any) {    
    this.langService.setCookieFavouriteLNG(lng, 'SIDE BAR MENU');         
    this.langService.getFirebaseTerms(lng);   // scarica i termini della lingua prescelta da firebase   
    this.errorService.getFirebaseErrorsTerms(lng);    // scarica gli errori della lingua prescelta da firebase   
  }

  unsubscribe() { 
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeOpensections() { 
    // console.log('>>> chiudo tutte le sezioni aperte ');
    this.openSections = { 
      vehicles: false,   
      users: false, 
      controlpanel: false,
      kitdoc: false,
      statistics: false
    };

    this.doctype = '';
  }

  expandMenuSection(sec: string) {   
    for (const i in this.openSections) { 
      if (i === sec) {
        this.openSections[i] =  !this.openSections[i] ;
      } else {
        this.openSections[i] = false;
      }
    }  
  }

  login() {
    this.auth.login();
  }
 
  logout() {
    this.auth.logout();
  }


  //? DARK | LIGHT THEME 
  setInitialTheme() {
    const currentTheme = localStorage.getItem('color-theme') || 'dark';
    this.applyTheme(currentTheme);
  }

  applyTheme(theme: string) {
    const themeConfig: any = {
      dark: { class: 'dark', icon: 'light_mode', name: 'sidebarmenu-light-theme' },
      light: { class: 'light', icon: 'dark_mode', name: 'sidebarmenu-dark-theme' }
    };

    const { class: themeClass, icon, name } = themeConfig[theme];

    document.documentElement.classList.remove('dark', 'light'); // per evitare conflitti li rimuovo entrambi
    document.documentElement.classList.add(themeClass);

    localStorage.setItem('color-theme', theme);
    this.themeIcon = icon;
    this.theme = name; // Light Theme  Dark Theme
  }

  switchTheme() {
    const newTheme = localStorage.getItem('color-theme') === 'dark' ? 'light' : 'dark';
    this.applyTheme(newTheme);
  } 

   
  //? GET PLATFORM INFO 
  getContactLinks() {
    this.utilService.getContactLinks().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (data) => {    

        this.store.dispatch(updatePlatform({ 
          update: { 
            website: data.webSite,
            companyStore:  data.store,
            supportEmail: data.supportEmail,
            companyName: data.companyName,
            companyLogo: data.companyLogo 
          } 
        }));

      },
      error: (err) => {  console.error('>>>> ERROR CONTACT LINKS', err) } 
    }); 

    this.appState$ = this.store.select(getAppState);
  } 


 
  fillSettingsFormData() { 
    this.store.select(getAppState).pipe(
      map((data: any) => {
        const newFormValues: any = {}; 
        data['loggedUser']?.['metadata']?.['settings'].forEach((field: any) => {
          newFormValues[field.name] = field.value;
        });
        return newFormValues;
      })
    ).subscribe(newFormValues => {
      this.formValues = newFormValues; 
    });
  }


   

    //-- TECH SHEET modal ***********************************************
    openModalKit(type: string) {  
      this.isLoadingDocumentsKitList$ = of(true); 
      this.docsService.retrieveDocumentsKitList(type).subscribe({
        next: (response) => { 
          this.isLoadingDocumentsKitList$ = of(false);
          this.docsKitList = response.data;
        },
        error: () => {
          this.isLoadingDocumentsKitList$ = of(false);
          this.docsKitList = [];
        }
      });  
      const modHtml = document.getElementById('kit-documents-modal') as HTMLElement;
      this.mod = new Modal(modHtml);
      this.mod.show(); 

      this.doctype = type;
    }

    closeModalKit() {    
      // this.selectedModelId = '';     
      this.mod?.hide(); 
    } 
  //********************************************************************

  openSettingsModal( modalId: string) {      
    const modHtml = document.getElementById(modalId) as HTMLElement;
    this.mod = new Modal(modHtml);
    this.isModalOpen = true;
    this.mod.show(); 
  }

  closeModal(modalId: string) {          
    this.isModalOpen = false;
    this.mod?.hide(); 
  }

  //? SAVING USER SETTINGS
  submitData( modalId: string ): void {  
    this.showError = false;
    this.loader = true;
   
    const payload = { settings: {...this.formValues} };   

    this.auth.setUserSettings( payload).pipe(takeUntil(this.unsubscribe$)).subscribe({ 
      next: (response: any) => {  
          this.loader = false;
          this.closeModal(modalId);  
          this.auth.getLoggedUser().pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (data: any) => {     
              if(data.metadata.missingSettings.length > 0 ) {
                this.router.navigate(['/']); 
              } 
            }
          }); 
      },
      error: (error: any) => { 
        console.error('ERROR', error); 
        this.showError = true;
        this.error = error.message;
        this.loader = false;
      } 
    });  
  }

 
}
