import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';   
import { CookieService } from 'ngx-cookie-service';
import { BROWSER_LANG_SHORT } from './../../app.module'; 

//? FIREBASE ------------------------------------------------------------- 
// import { traceUntilFirst } from '@angular/fire/performance';
import {  DataSnapshot, Database, get, child, onValue, query, ref, set, update, remove, orderByKey } from '@angular/fire/database';
// import {  DataSnapshot, Database, get, push, child,  objectVal, onValue, query, ref, set, update, remove, orderByKey } from '@angular/fire/database';
// import { getDatabase, child, limitToLast,  orderByChild, orderByKey, orderByValue, push  } from 'firebase/database';
// import { addDoc, collection, collectionData, doc, Firestore, increment, orderBy, query, serverTimestamp, updateDoc } from '@angular/fire/firestore';
//? --------------------------------------------------------------------------


@Injectable({
  providedIn: 'root'
})
export class LanguagesService {  
 
  platform$: BehaviorSubject<string> = new BehaviorSubject<string>('webapp');
  basePath = this.platform$.value;

  translations$: BehaviorSubject<any> = new BehaviorSubject<any>(null); //? FIREBASE TRASNLATION idea *********************************
  translationsArray$: BehaviorSubject<any> = new BehaviorSubject<any>([]);  //? FIREBASE TRASNLATION idea *********************************
  transCount = 0;  //? FIREBASE TRASNLATION idea ********************************* 

  languages: any[] = []; 

  jsonTerms: any = null; 

  constructor(    
    private database: Database,  
    private cookieService: CookieService) {   } 

  //? FIREBASE TRASNLATION *********************************
    getFirebaseTerms(ln: string){    

        // console.log('CALLED ::: getFirebaseTerms');

        this.basePath = this.platform$.value  ;    // webapp

        if(ln === '') return;      
        const collection  = query(ref(this.database, `${this.basePath}/translations/${ln}`),  orderByKey() );
      
        onValue(collection, (snapshot: DataSnapshot) => {      
          const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON
          this.translations$.next( jsonRes );  
          const dataArr = Object.entries(jsonRes).map(([selector, translation]) => {
            return { selector, translation };
          }); 
          this.translationsArray$.next(dataArr) ; // for table
          this.transCount = dataArr.length;
        }, (errorObject: any) => {
          console.error('>>>> retrieve fb translations ' , errorObject.name);
        }); 
    }
   
    updateTranslation(definition: string, selector: string) {   
      selector = selector.toLowerCase();

      const ln = this.checkMyFavouriteLangauge();   
      const doc = ref(this.database,  `${this.basePath}/translations/${ln}/${selector}`);
      set(  doc, definition )
          .then(() => {
            // console.log('>>>>> updateTranslation Firebase item: OK'); 
          })
          .catch((error) => {
            console.error('>>>>> updateTranslation Firebase: error', error);
          }); 
    }
  
    //  delete the same selector for all languages
    deleteTerm(selector: string) {   
      const isOk = confirm(this.translations$.value['confirm-to-delete-term']) ;

      if (isOk) {
        for( const ln of this.languages) {  
          const doc = ref(this.database,  `${this.basePath}/translations/${ln.value}/${selector}`);
          remove(doc).then(() => {
            // console.log('>>>>> delete Firebase item: success'); 
          }).catch((error) => {
            console.error('>>>>> delete Firebase: error', error);
          }); 
        }
      }
    }
    
    // add a new selector for all languages
    addNewTerm(definition: string, selector: string) {   
      // definition = definition.toLowerCase(); // RIMOSSO perché impossibilitata a inserire URL complesse
      selector = selector.toLowerCase(); 
      selector = selector.replace(/\./g, '-');  

      for( const lan of this.languages) { 
        const nodeLang = ref(this.database, `${this.basePath}/translations/${lan.value}`);
        const data = { [String(selector)]: definition };
  
        update(nodeLang, data).then(() => {
          // console.log('>>>>> addNewTerm Firebase: success', lan.value); 
        }).catch((error) => {
          console.error('>>>>> addNewTerm Firebase: error', error);
        });  
      } 
    } 

    addNewLanguage(lng: string, label: string) {  
        const data = {  
          'ico': `https://purecatamphetamine.github.io/country-flag-icons/3x2/${lng.toUpperCase()}.svg`, 
          'label': label,
          'value': lng
        }; 
        const languagesRef = ref(this.database, `${this.basePath}/languages`); 

        // Ottieni l'elenco corrente dei linguaggi, aggiungi il nuovo linguaggio e poi aggiorna il nodo del database.
        get(child(languagesRef, '/')).then((snapshot) => {
          if (snapshot.exists()) {
            const currentLanguages = snapshot.val();
            currentLanguages.push(data);
            set(languagesRef, currentLanguages);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        }); 

        // creo un set di selettori copiando l'ultimo set in inglese
        const collection  = query(ref(this.database, `${this.basePath}/translations/en`));
        let data2: any = null;
        onValue(collection, (snapshot: DataSnapshot) => {      
          data2 =  snapshot.val();   
          data2['app-language-flag'] = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${lng.toUpperCase()}.svg`;
          const newTranslationsNode = ref(this.database, `${this.basePath}/translations/${lng}`); 
          update(newTranslationsNode, data2);
        }, (errorObject: any) => {
          console.error('>>>> retrieve fb translations ' , errorObject.name);
        });  

    }
  
    // for the select of languages
    getFirebaselangs() { 
      const collection  = query(ref(this.database, `${this.basePath}/languages`));
      onValue(collection, (snapshot: DataSnapshot) => {      
        const jsonRes =  snapshot.val();   // trasformo snapshot in un JSON         
        // const languageKeys = Object.keys(jsonRes);
        this.languages = jsonRes;
        //  console.log('>>> LANGs KEYs ', jsonRes);
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb translations ' , errorObject.name);
      }); 
    }
    
  //? ****************************************************************


 
 

 

  //? cookie favourite language
  checkMyFavouriteLangauge(): string {
    if (this.cookieService.check('chosen-lang')) return this.cookieService.get('chosen-lang') ; 
    this.setCookieFavouriteLNG(BROWSER_LANG_SHORT, 'checkMyFavouriteLangauge if non cookie found');
    return BROWSER_LANG_SHORT;
  } 
  setCookieFavouriteLNG(lang: string, who: string) {   
    console.log('>>> setCookieFavouriteLNG', lang, who);
    this.cookieService.delete('chosen-lang');  
    this.cookieService.set('chosen-lang', lang, 365, '/');   
  }
  getCookieFavouriteLNG(): string {   
    return this.cookieService.get('chosen-lang');
  } 

   
}
