<div  *ngFor="let toast of toasts" class="toast" [ngClass]="'toast-'+ toast.type" [@fadeInOut] >
 


     <!-- SETTINGS TOAST -->
    <div *ngIf="toast.type === 'settings'"class="flex pr-3 "> 
      <div class="ml-3 w-full">
        <div class="header ">
          <h3 > {{ toast.title  }}</h3> 
          <!-- NEVER CLOSE WITHOUT SUBMITTING // WAS closeToast(toast.id)  -->
          <button type="button" (click)="submitData(toast.id )"> 
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
          <div class="body py-4">   
            
              <fieldset class="p-4 rounded-md border-1 border-gray-300">  
               <legend class="px-2 text-md font-medium  text-gray-700 uppercase">{{ toast.message.legend }}</legend>   

               <div *ngIf="showError" class="err">{{ toast.message.error }}</div>
       
                <div  *ngFor="let item of toast.message.settings " class="box"> 
                  <div class="disclaimer first-letter:capitalize">{{ item?.disclaimer}}</div>
                  <label *ngIf="item?.type === 'checkbox'"  class=" relative cursor-pointer  flex items-center ">  
                    <input 
                        type="checkbox"  
                        class="sr-only peer"  
                        [(ngModel)]="formValues[item?.name]" 
                        [ngClass]="{'disabled': item?.readonly}"  
                        [checked]="item?.value" 
                        [name]="item?.name" 
                        [readonly]="item?.readonly"    >

                    <div class=" mb-2 w-14 h-8 after:top-[4px] after:left-[5px] after:h-6 after:w-6 
                        peer-checked:after:translate-x-full  after:content-[''] after:border after:rounded-full after:transition-all  rounded-full peer peer-focus:ring-4 after:absolute 
                        border-[1px] border-black bg-gray-300 after:bg-gray-400 after:border-gray-600   peer-checked:after:border-white peer-checked:after:bg-white
                        toggleGreen"></div>  
                    <div class="label">{{ item?.label  }}</div>  
                  </label> 

                  <div *ngIf="item?.type === 'text'"    class=" relative cursor-pointer  flex items-center">  
                      <input  
                          [(ngModel)]="formValues[item?.name]" 
                          [ngClass]="{'disabled': item?.readonly}"  
                          [readonly]="item?.readonly" 
                          [value]="item?.value"   
                          [name]="item?.name" 
                          type="text" 
                          [required]="item?.mandatory"  
                          class="actInputFld mb-2" 
                          style="background-color: white; color: black"  > 

                          <label class="label  first-letter:capitalize block">{{ item?.label   }}</label>  
                    </div>

                    <!-- <a *ngIf="item?.url" [href]="item?.url" target="_blank" [title]="item?.url">File link</a> -->
                    <a  *ngIf="item?.url" [href]="item?.url" target="_blank"  class="flex items-center" >
                      <span class="material-symbols-outlined mr-2"> link </span>
                      <span>{{  (langService.translations$ | async  )?.['modal-link-read-document'] ?? 'modal-link-read-document' }} </span>
                    </a>

                    
                </div>


                <div class=" w-full h-14 flex items-center justify-between">
                    <!-- SPINNER  -->
                    <div class="container">
                      <app-spinner  *ngIf="loader" [color]="'grey'"></app-spinner> 
                    </div> 
                    <button  type="button" (click)="submitData(toast.id )"   > 
                      <span  class="ico cursor-pointer material-symbols-outlined">
                        save
                      </span>    
                      <span class=" uppercase font-semibold">{{  toast.message.btnlabel }}</span> 
                    </button>
                  </div>
               
              </fieldset> 


          </div>
      </div>  
    </div>


    <!-- TUTTI GLI INFO TOASTs  -->
    <div *ngIf="toast.type !== 'settings'" class="flex p-4">
        <div class="flex-shrink-0">
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
        </svg>
        </div>
        <div class="ml-3">
            <h3 class="uppercase">{{  (langService.translations$ | async  )?.[toast.title] ?? toast.title }} </h3>
            <div class="body">
                <ul role="list" class="list-disc space-y-1 pl-5">
                    <li *ngFor="let toast of toasts">{{ toast.message }} </li> 
                </ul>
            </div>
        </div> 
        <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button type="button" (click)="closeToast(toast.id)">
                <span class="sr-only">Dismiss</span>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
        </div>
    </div>


</div>


 